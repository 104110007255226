import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from '../openapi-types';

type UserResponse = components['schemas']['ListUsersResponse'];
export type AccountUser = UserResponse['result'];
export type UserRole = NonNullable<components['schemas']['User']['roles']>[number];
export type AssignableUserRole = NonNullable<
  components['schemas']['CreateUserRequest']['roles']
>[number];

export const useGetAccountUsers = () => {
  return useQuery({
    queryKey: ['users', 'account'],

    queryFn: async () => {
      const result = await AxiosService.get<UserResponse>(`users`);
      return result;
    },

    staleTime: 5000,
    gcTime: 1000,
  });
};

export type User = components['schemas']['User'] & {
  type: 'site' | 'account';
  sites?: Array<number>;
  created: string;
};

type UserListResponse = components['schemas']['BasicListResponse'] & {
  result?: User[];
};

// api mocked until backend builds it
export const useGetUser = (userId: string) => {
  return useQuery({
    queryKey: ['users', userId],

    queryFn: async () => {
      const idIndex = Number(userId.slice(-1));

      return new Promise<{
        result: User;
      }>(resolve => {
        setTimeout(() => {
          resolve({
            result: {
              id: `some-id-${idIndex}`,
              firstname: `FirstName${idIndex + 1}`,
              lastname: `LastName${idIndex + 1}`,
              sites: [100, 105],
              twofactor_enabled: idIndex % 2 === 0 ? true : false,
              email: `someemail${idIndex}@gmail.com`,
              twofactor_required: false,
              status: idIndex % 2 === 0 ? 'active' : 'invited',
              type: idIndex % 3 === 0 ? 'account' : 'site',
              created: new Date().toISOString(),
            },
          });
        }, 2000);
      });
    },
  });
};

export const useGetUsers = () => {
  return useQuery({
    queryKey: ['users'],

    queryFn: async () => {
      const mockData: User[] = Array.from({ length: 10 }).map((v, i) => ({
        id: `some-id-${i}`,
        firstname: `FirstName${i + 1}`,
        lastname: `LastName${i + 1}`,
        sites: [100, 105],
        twofactor_enabled: i % 2 === 0 ? true : false,
        email: `someemail${i}@gmail.com`,
        twofactor_required: false,
        status: i % 2 === 0 ? 'active' : 'invited',
        type: i % 3 === 0 ? 'account' : 'site',
        created: new Date().toISOString(),
        roles: ['account_user', 'billing_admin'],
      }));

      return {
        metadata: {
          page: 1,
          page_size: 10,
          total: 100,
        },
        result: mockData,
      } as UserListResponse;
    },

    staleTime: 5000,
    gcTime: 1000,
  });
};

export type InviteUserData = components['schemas']['CreateUserRequest'];

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['invite-user'],

    mutationFn: async (data: InviteUserData) => {
      const result = await AxiosService.post<UserResponse>(`users`, data);
      return result;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
};

export const useUserAccept = () => {
  return useMutation({
    mutationKey: ['user-accept'],

    mutationFn: async (token: string) => {
      const result = await AxiosService.get<components['schemas']['SuccessResponse']>(
        `users/accept?token=${token}`
      );
      return result;
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const result = await AxiosService.delete<components['schemas']['SuccessResponse']>(
        `users/${userId}`
      );
      return result;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
};

export const useReinviteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const result = await AxiosService.post<components['schemas']['SuccessResponse']>(
        `users/${userId}/reinvite`
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUserChangeTwoAuth = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['change-2fa-user'],
    mutationFn: async ({ twofa, userId }: { twofa: boolean; userId: string }) => {
      const result = await AxiosService.patch<components['schemas']['UpdateUserRequest']>(
        `users/${userId}`,
        {
          twofactor_required: twofa,
        }
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
};

export const useMeChangeTwoAuth = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['change-2fa-my-user'],
    mutationFn: async ({ twofa }: { twofa: boolean }) => {
      const result = await AxiosService.patch<components['schemas']['GetAccountMeResponse']>(
        '/account/me',
        {
          twofactor_enabled: twofa,
        }
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile'],
      });
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: { userId: string; data: components['schemas']['UpdateUserRequest'] }) =>
      AxiosService.patch<components['schemas']['UserResponse']>(
        `users/${input.userId}`,
        input.data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUserRevokeSites = () => {
  return useMutation({
    mutationKey: ['user-revoke-sites'],

    mutationFn: async (data: { siteIds: string[] }) => {
      return Promise.all(
        data.siteIds.map(async siteId => {
          return new Promise<{
            data: {
              success: true;
            };
          }>(res => {
            setTimeout(() => {
              res({
                data: {
                  success: true,
                },
              });
            }, 500);
          });
        })
      ).then(values => {
        if (values.every(value => value.data.success === true)) {
          return {
            data: {
              success: true,
            },
          };
        }

        return {
          data: {
            success: false,
          },
        };
      });
    },
  });
};
