import { Alert, Typography } from '@mui/material';
import { forwardRef } from 'react';
import CheckCircle from 'component/new_design/icons/CheckCircle.svg?react';
import AlertTriangle from 'component/new_design/icons/AlertTriangle.svg?react';
import { IconWrapper } from './IconWrapper';
import { useSnackbar } from 'component/hooks/useSnackbar';

interface SnackbarProps {
  readonly id: string;
  readonly variant: 'success' | 'error';
  readonly message: string;
}

export const Snackbar = forwardRef(({ id, variant, message }: SnackbarProps, ref: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Alert
      ref={ref}
      severity={variant}
      variant="filled"
      icon={
        <IconWrapper size="s">
          {variant === 'success' ? <CheckCircle /> : <AlertTriangle />}
        </IconWrapper>
      }
      onClose={() => closeSnackbar(id)}
    >
      <Typography variant="body2" fontWeight={600}>
        {message}
      </Typography>
    </Alert>
  );
});
