import { ChangeEvent, FC, useCallback } from 'react';
import { PaginationItem, Stack } from '@mui/material';
import { Select } from 'component/new_design/base/forms/Select';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { saveDefaultPerPage } from 'utils/paginate';
import ChevronLeft from 'component/new_design/icons/ChevronLeft.svg?react';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';

interface PaginationControlProps {
  readonly disabled?: boolean;
  readonly totalRowCount: number;
  readonly state: PaginatedListState;
}

export const PaginationControls: FC<PaginationControlProps> = ({
  totalRowCount,
  disabled = false,
  state,
}) => {
  const { t } = useTranslation();
  const { setPerPage, setPage, params, config } = state;

  const handlePerPageSelectChange = useCallback(
    (value: number) => {
      saveDefaultPerPage(config.perPageOptions, value);
      setPerPage(value);
    },
    [setPerPage]
  );

  const handlePaginationChange = useCallback(
    (_event: ChangeEvent<any>, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const pageCount = Math.ceil(totalRowCount / params.perPage);

  if (totalRowCount < config.perPageOptions[0]) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      {config.perPageOptions.length > 1 ? (
        <Select
          disabled={disabled}
          value={params.perPage}
          onChange={handlePerPageSelectChange}
          options={config.perPageOptions.map(size => ({
            label: t('per_page', { count: size }),
            value: size,
          }))}
        />
      ) : (
        <div />
      )}
      <Pagination
        onChange={handlePaginationChange}
        page={params.page}
        count={pageCount}
        renderItem={item => (
          <PaginationItem slots={{ previous: ChevronLeft, next: ChevronRight }} {...item} />
        )}
      />
    </Stack>
  );
};
