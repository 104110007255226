import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

interface CheckboxFieldProps extends Omit<CheckboxProps, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly label: ReactNode;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
}

export const CheckboxField = ({ name, label, rules, ...rest }: CheckboxFieldProps) => {
  const {
    field: { ref, value, ...field },
    formState: { isSubmitting },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          className="checkbox-radio"
          inputRef={ref}
          {...rest}
          {...field}
          checked={value}
          disabled={field.disabled || isSubmitting}
        />
      }
      label={<Typography>{label}</Typography>}
      className="checkbox-form-control-label"
    />
  );
};
