import { AssignableUserRole, UserRole } from 'api/users';

export type UserRoleCheckboxes = {
  role_account_user: Boolean;
  role_billing_admin: Boolean;
  role_user_admin: Boolean;
};

export const rolesDefaultValues: UserRoleCheckboxes = {
  role_account_user: true,
  role_billing_admin: false,
  role_user_admin: false,
};

export const convertToRolesArray = (data: UserRoleCheckboxes) => {
  const rolesArray = Object.keys(data)
    .filter(key => key.startsWith('role_') && (data as any)[key])
    .map(key => key.replace('role_', '')) as AssignableUserRole[];

  return rolesArray;
};

export const getRoleCheckboxValues = (roles?: UserRole[]) => {
  return {
    role_account_user: !!roles?.includes('account_user'),
    role_billing_admin: !!roles?.includes('billing_admin'),
    role_user_admin: !!roles?.includes('user_admin'),
  } satisfies UserRoleCheckboxes;
};

export const rolesFormResolver = <T extends UserRoleCheckboxes>(data: T) => {
  const atLeastOneRoleSelected = convertToRolesArray(data).length > 0;

  return {
    values: data,
    errors: atLeastOneRoleSelected ? {} : { role_account_user: true },
  };
};
