import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  AppBar,
  Container,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { cssVar } from 'utils/css';
import { useProfile } from 'api/profile';
import { linkHelper } from 'linkHelper';
import Cookies from 'js-cookie';

const PREFIX = 'Header';

const classes = {
  logo: `${PREFIX}Logo`,
  list: `${PREFIX}List`,
  button: `${PREFIX}Button`,
  buttonToggle: `${PREFIX}ButtonToggle`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.logo}`]: {
    padding: '0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${classes.list}`]: {
    padding: '0.625rem 0',
    '& .MuiListItem-button': {
      backgroundColor: 'transparent',
      color: cssVar('--color-white'),
      textTransform: 'uppercase',
      transition: 'none',
      '&:hover': {
        backgroundColor: cssVar('--color-shark'),
        color: cssVar('--color-curious-blue'),
      },
      '& svg:first-of-type': {
        marginRight: '0.75rem',
      },
    },
    '& .Mui-selected': {
      backgroundColor: cssVar('--color-shark'),
      color: cssVar('--color-curious-blue'),
    },
    '& .MuiCollapse-root': {
      borderColor: cssVar('--color-curious-blue'),
      borderBottomWidth: '0',
      backgroundColor: cssVar('--color-shark'),
      '& .MuiList-root > *': {
        '&:not(:last-child)': {
          borderBottomWidth: '0',
        },
      },
      '& .MuiListItem-button': {
        textTransform: 'none',
      },
    },
    '& > *': {
      '&:not(:last-child)': {
        borderBottomWidth: 0,
      },
    },
  },

  [`& .${classes.button}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *:first-of-type': {
      backgroundColor: cssVar('--color-blue-dianne'),
      color: cssVar('--color-regent-gray'),
      fontSize: '0.75rem',
      fontWeight: '400',
      height: '2rem',
      marginRight: '0.3125rem',
      width: '2rem',
    },
    '& > *:last-child': {
      color: cssVar('--color-curious-blue'),
      '& svg': {
        width: '0.75rem',
        height: '0.75rem',
      },
    },
  },

  [`& .${classes.buttonToggle}`]: {
    height: '2rem',
    width: '2rem',
    color: cssVar('--color-curious-blue'),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export function SiteUserHeader() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useProfile();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id="user-menu"
      keepMounted
      onClose={handleClose}
      open={open}
    >
      <MenuItem
        onClick={() => {
          Cookies.remove('user_session');
          window.location.href = `${window.location.origin}/login`;
        }}
      >
        {t('logout')}
      </MenuItem>
    </Menu>
  );

  return (
    <Root>
      <AppBar
        position="relative"
        sx={{
          backgroundColor: cssVar('--color-outer-space'),
        }}
      >
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              aria-label="logo"
              className={classes.logo}
              onClick={() => navigate(linkHelper.dashboard.getLink())}
            >
              <Box>
                <Typography
                  variant="h2"
                  display="block"
                  sx={{
                    color: cssVar('--color-white'),
                  }}
                >
                  {t('wordpress')}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: cssVar('--color-white'),
                  }}
                >
                  {t('management_panel')}
                </Typography>
              </Box>
            </IconButton>
            <IconButton
              aria-controls="user-menu"
              aria-haspopup="true"
              aria-label="Account of current user"
              onClick={handleMenu}
              className={classes.button}
              sx={{ position: 'absolute', right: '0', alignItems: 'center' }}
            >
              <Avatar
                alt={`${data?.data.result?.client?.firstname} ${data?.data.result?.client?.lastname}`}
              >
                {data?.data.result?.client?.firstname?.charAt(0) ?? ''}
                {data?.data.result?.client?.lastname?.charAt(1) ?? ''}
              </Avatar>
              <Box
                sx={{
                  padding: '3px 8px 3px 8px',
                  backgroundColor: cssVar('--color-shark'),
                  borderRadius: '5px',
                }}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </Box>
            </IconButton>
            {userMenu}
          </Toolbar>
        </Container>
      </AppBar>
    </Root>
  );
}
