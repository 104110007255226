import { Stack } from '@mui/material';
import { useUpdateUser } from 'api/users';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { CheckboxField } from 'component/new_design/base/forms/CheckboxField';
import { components } from 'openapi-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { convertToRolesArray, getRoleCheckboxValues, rolesFormResolver } from 'utils/user';

interface UserRoleDialogProps {
  readonly user: components['schemas']['User'];
  readonly onClose: () => void;
}

interface UserRoleFormData {
  role_account_user: Boolean;
  role_billing_admin: Boolean;
  role_user_admin: Boolean;
}

export const UserRoleDialog = ({ user, onClose }: UserRoleDialogProps) => {
  const { t } = useTranslation();
  const updateUser = useUpdateUser();

  const methods = useForm<UserRoleFormData>({
    defaultValues: getRoleCheckboxValues(user.roles),
    resolver: rolesFormResolver,
  });

  const handleSubmit = async (data: UserRoleFormData) => {
    await updateUser.mutateAsync({
      userId: user.id!,
      data: { roles: convertToRolesArray(data) },
    });

    onClose();
  };

  return (
    <FormDialog
      methods={methods}
      form={
        <Stack>
          <CheckboxField name="role_account_user" label={t('account_user')} />
          <CheckboxField name="role_billing_admin" label={t('billing_admin')} />
          <CheckboxField name="role_user_admin" label={t('user_admin')} />
        </Stack>
      }
      onSubmit={handleSubmit}
      onClose={onClose}
      title={t('change_user_role')}
      submitText={t('save')}
    />
  );
};
