import { ReactNode, useId } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  DialogProps,
  DialogContentProps,
} from '@mui/material';
import { useDialogProps } from 'component/hooks/useDialogProps';
import Close from 'component/new_design/icons/Close.svg?react';
import { useTranslation } from 'react-i18next';

export interface BaseDialogProps
  extends Omit<DialogProps, 'open' | 'onClose' | 'title' | 'content'> {
  readonly open?: boolean;
  readonly title: ReactNode;
  readonly onClose?: () => unknown;
  readonly actions?: ReactNode;
  readonly content: ReactNode;
  readonly contentProps?: DialogContentProps;
  readonly headerContent?: ReactNode;
  readonly footerContent?: ReactNode;
  readonly preventAccidentalClose?: boolean;
}

export function BaseDialog({
  actions,
  content,
  contentProps,
  title,
  onClose,
  open = true,
  preventAccidentalClose = false,
  headerContent,
  footerContent,
  ...rest
}: BaseDialogProps) {
  const { t } = useTranslation();
  const sdkDialogProps = useDialogProps(onClose, preventAccidentalClose);
  const dialogId = useId();
  const titleId = useId();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      id={dialogId}
      open={open}
      aria-labelledby={titleId}
      {...rest}
      {...sdkDialogProps}
    >
      <DialogTitle id={titleId}>
        {title}
        <IconButton
          disabled={!onClose || preventAccidentalClose}
          onClick={onClose}
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {headerContent}
      <DialogContent {...contentProps}>{content}</DialogContent>
      {footerContent}
      <DialogActions>
        {actions}
        <Button data-testid="closeButton" disabled={!onClose} onClick={onClose} variant="text">
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
