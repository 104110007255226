import { Stack, Typography } from '@mui/material';
import { useUpdateUser } from 'api/users';
import { CheckboxField } from 'component/base/CheckboxField';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { components } from 'openapi-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  convertToRolesArray,
  getRoleCheckboxValues,
  rolesFormResolver,
  UserRoleCheckboxes,
} from 'utils/user';
import React from 'react';

interface ChangeUserRoleDialogProps {
  readonly user: components['schemas']['User'];
  readonly onClose: () => void;
}

export const ChangeUserRoleDialog = ({ user, onClose }: ChangeUserRoleDialogProps) => {
  const { t } = useTranslation();
  const updateUser = useUpdateUser();

  const methods = useForm<UserRoleCheckboxes>({
    defaultValues: getRoleCheckboxValues(user.roles),
    resolver: rolesFormResolver,
  });

  const handleConfirm = async (data: UserRoleCheckboxes) => {
    await updateUser.mutateAsync({
      userId: user.id!,
      data: { roles: convertToRolesArray(data) },
    });
    onClose();
  };

  return (
    <ConfirmationDialog
      open
      action="confirm"
      onConfirm={methods.handleSubmit(handleConfirm)}
      confirmDisabled={!methods.formState.isValid}
      description={
        <FormProvider {...methods}>
          <Stack component="form" onSubmit={methods.handleSubmit(handleConfirm)}>
            <CheckboxField name="role_account_user" label={t('account_user')} />{' '}
            <Typography variant="body2" mb={2}>
              {t('account_user_description')}
            </Typography>
            <CheckboxField name="role_billing_admin" label={t('billing_admin')} />{' '}
            <Typography variant="body2" mb={2}>
              {t('billing_admin_description')}
            </Typography>
            <CheckboxField name="role_user_admin" label={t('user_admin')} />{' '}
            <Typography variant="body2" mb={2}>
              {t('user_admin_description')}
            </Typography>
          </Stack>
        </FormProvider>
      }
      onClose={onClose}
      title={t('change_user_role')}
    />
  );
};
