import React, { useId } from 'react';
import { BaseDialog, BaseDialogProps } from './BaseDialog';
import { Table } from '../Table/Table';
import { TableRowType, TableProps } from '../Table/types';
import { Box, Stack } from '@mui/material';
import { SearchInput } from '../SearchInput';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { PaginationControls } from '../PaginationControls';

interface TableDialogProps<T extends TableRowType> extends Omit<BaseDialogProps, 'content'> {
  readonly open?: boolean;
  readonly title: React.ReactNode;
  readonly onClose?: () => unknown;
  readonly tableProps: TableProps<T>;
}

export const TableDialog = <T extends TableRowType>({
  title,
  onClose,
  open = true,
  tableProps,
  ...rest
}: TableDialogProps<T>) => {
  const { params, setSearch } = tableProps.state;
  const searchInputProps = useDebouncedInputProps(params.search, setSearch);
  const loadingState = usePaginatedListLoadingState(
    !!tableProps.data.length,
    tableProps.isLoading,
    params
  );

  return (
    <BaseDialog
      contentProps={{
        sx: theme => ({
          padding: `0 ${theme.spacing(4)} !important`,
        }),
      }}
      onClose={onClose}
      open={open}
      title={title}
      headerContent={
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          p={4}
        >
          <Box>
            {tableProps.title}
            {tableProps.description}
          </Box>
          {loadingState === 'noData' ? null : (
            <>
              {tableProps.enableSearch ? (
                <SearchInput {...searchInputProps} placeholder={tableProps.searchPlaceholder} />
              ) : null}
              {tableProps.children}
            </>
          )}
        </Stack>
      }
      content={
        <Stack gap={4}>
          <Table {...tableProps} />
        </Stack>
      }
      footerContent={
        tableProps.enablePagination ? (
          <Box p={4} minHeight="64px">
            <PaginationControls state={tableProps.state} totalRowCount={tableProps.totalRowCount} />
          </Box>
        ) : null
      }
      {...rest}
    />
  );
};
