import { Metric, useProfileSuspense } from 'api/profile';
import { useTranslation } from 'react-i18next';
import { abbreaviateNumber, formatDataSize } from 'utils/number';
import { MetricsCard } from './MetricsCard';
import { MetricsGrid } from './MetricsGrid';

export const DashboardMetrics = () => {
  const { t } = useTranslation();
  const { data } = useProfileSuspense();

  const usages = data?.data.result?.usage;
  const limits = data?.data.result?.limits;

  const packageId = data?.data.result?.package?.package_id;
  const isEnterprise = data?.data.result?.package?.plan === 'Enterprise';

  const hasReachedUsageLimit = (metric: Metric): boolean => {
    if (isEnterprise || !usages || !limits) {
      return false;
    }

    const isUnlimited = metric === 'visitors' && limits[metric] === 0;
    const hasReachedLimit = usages[metric]! >= limits[metric]!;

    return !isUnlimited && hasReachedLimit;
  };

  const formatVisitors = (value: number | undefined, isLimitValue: boolean) =>
    isLimitValue && value === 0 ? t('unlimited') : abbreaviateNumber(value);

  return (
    <MetricsGrid>
      <MetricsCard
        title={t('sites')}
        usage={usages?.sites}
        limit={limits?.sites}
        offerUpgrade={!isEnterprise}
        packageId={packageId!}
      />
      <MetricsCard
        title={t('visitors')}
        usage={usages?.visitors}
        limit={limits?.visitors}
        formatValue={formatVisitors}
        offerUpgrade={hasReachedUsageLimit('visitors')}
        packageId={packageId!}
      />
      <MetricsCard
        title={t('disk_usage')}
        usage={usages?.disk}
        limit={limits?.disk}
        formatValue={v => formatDataSize(v, false, 'auto')}
        offerUpgrade={hasReachedUsageLimit('disk')}
        packageId={packageId!}
      />
      <MetricsCard
        title={t('bandwidth')}
        usage={usages?.bandwidth}
        limit={limits?.bandwidth}
        formatValue={v => formatDataSize(v, false, 'auto')}
        offerUpgrade={hasReachedUsageLimit('bandwidth')}
        packageId={packageId!}
      />
    </MetricsGrid>
  );
};
