import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_URL } from '../constants';
import { AxiosService } from 'api/axiosService';
import { components, operations } from 'openapi-types';

interface LoginData {
  username: string;
  password: string;
}

export const useLogin = () => {
  const instance = axios.create({
    baseURL: `${API_URL}/v1/`,
  });
  instance.defaults.headers.common = {};

  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (data: LoginData) => await instance.post<{ token: string }>('login', data),
  });
};

/** method to log in an invited user */
export const useUserSiteLogin = () => {
  const instance = axios.create({
    baseURL: `${API_URL}/v1/`,
  });
  instance.defaults.headers.common = {};

  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (data: LoginData) =>
      await instance.post<{ token: string }>('sites/users/login', data),
  });
};

export interface InviteUserData {
  firstName: string;
  lastName: string;
  email: string;
}

export const useUserSiteInvites = () => {
  return useMutation({
    mutationKey: ['user-send-invites'],

    mutationFn: async (data: InviteUserData & { siteIds: string[] }) => {
      return Promise.all(
        data.siteIds.map(async siteId => {
          return AxiosService.post<{ success: boolean }>(`${API_URL}/v1/sites/${siteId}/users`, {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
          });
        })
      ).then(values => {
        if (values.every(value => value.data.success === true)) {
          return {
            data: {
              success: true,
            },
          };
        }

        return {
          data: {
            success: false,
          },
        };
      });
    },
  });
};

export const useUserSiteInvite = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['user-send-invite'],

    mutationFn: async (data: InviteUserData) =>
      await AxiosService.post<{ success: boolean }>(`${API_URL}/v1/sites/${siteId}/users`, {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sites/{id}/users', siteId],
      });
    },
  });
};

// TODO - this is not defined
export const useUserResendAllSitesPendingInvite = () => {
  return useMutation({
    mutationKey: ['user-resend-all-pending-invites'],

    mutationFn: async ({ userId }: { userId: string }) =>
      await AxiosService.post<{ token: string }>(
        `${API_URL}/v1/sites/users/${userId}/reinvite`,
        {}
      ),
  });
};

export const useUserResendSiteInvite = (siteId: string) => {
  return useMutation({
    mutationKey: ['user-resend-invite', siteId],

    mutationFn: async (userId: number) =>
      await AxiosService.post<{ token: string }>(
        `${API_URL}/v1/sites/${siteId}/users/${userId}/reinvite`,
        {}
      ),
  });
};

type SetPasswordData = {
  password: string;
  token: string;
};

export const useSetPassword = (userId: string) => {
  const instance = axios.create({
    baseURL: `${API_URL}/v1/`,
  });
  instance.defaults.headers.common = {};

  return useMutation({
    mutationKey: ['user-set-password'],

    mutationFn: async (data: SetPasswordData) =>
      await instance.post<{ success: true }>(`sites/users/${userId}/password`, data),
  });
};

export const useSetUserPassword = (userId: string) => {
  return useMutation({
    mutationKey: ['user-set-password'],

    mutationFn: async (data: SetPasswordData) =>
      await AxiosService.post<{ success: true }>(`users/${userId}/password`, data),
  });
};

export const useAcceptInvite = (siteId: string, token: string) => {
  return useQuery({
    queryKey: ['user-accept-invite'],

    queryFn: async () =>
      await AxiosService.get<{ success: string }>(`sites/${siteId}/users/accept?token=${token}`),

    retry: 0,
    gcTime: 0,
  });
};

export const useAcceptInviteLoggedIn = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['user-accept-invite-by-id'],

    mutationFn: async ({ siteId }: { siteId: number }) =>
      await AxiosService.post<{ success: string }>(`sites/${siteId}/users/accept`),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sites-for-user'],
      });
    },
  });
};

export const useResetPassword = () => {
  const instance = axios.create();
  instance.defaults.headers.common = {};

  return useMutation({
    mutationKey: ['reset-password'],

    mutationFn: async ({ email }: { email: string }) =>
      await instance.post<{ success: string }>(`${API_URL}/v1/sites/users/reset-password`, {
        email,
      }),
  });
};

export const useChangePassword = () => {
  return useMutation({
    mutationKey: ['update-password'],

    mutationFn: async (data: components['schemas']['UpdatePasswordRequest']) =>
      await AxiosService.post<components['schemas']['SuccessResponse']>('/account/password', data),
  });
};

export const useBillingSso = () => {
  return useMutation({
    mutationKey: ['billing-sso'],

    mutationFn: async (
      data: NonNullable<
        operations['sites-api.openapi_server.controllers.account_controller.account_billing_sso_post']['requestBody']['content']['application/json']
      >
    ) =>
      await AxiosService.post<components['schemas']['BillingSSOResponse']>('/account/billing_sso', {
        destination: data.destination,
        params: data.params,
      }),
  });
};
