import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { linkHelper } from 'linkHelper';
import { BackButton } from './BackButton';
import { RoleGuard } from 'component/base/RoleGuard';
import { ClearCacheDialog } from './SiteDetails/Overview/ClearCacheDialog';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { useContext, useState } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isSiteAccess, useGetToken } from 'utils/token';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';
import { isInIframe } from 'utils/iframe';
import { formatDomainName } from 'utils/formatting';
import { SiteSelectorMenu } from './SiteSelectorMenu';
import { ChangeLabelDialog } from './ChangeLabelDialog';
import { Link, useParams } from 'react-router-dom';
import { useSiteDetails } from 'api/site';

import PencilEdit from '../icons/PencilEdit.svg?react';
import Wordpress from '../icons/Wp.svg?react';
import Share from '../icons/Share.svg?react';

export const SiteDetailsHeader = () => {
  const { siteId } = useParams<{ siteId: string }>();

  const { data, isLoading } = useSiteDetails({ id: siteId! });

  const siteDetails = data?.data.result;

  // TODO - get this from API
  const isSiteLocked = false;
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const [showSiteLabelDialog, setShowSiteLabelDialog] = useState(false);
  const token = useGetToken();
  const isBillingAdmin = useCheckUserRole('billing_admin');

  return (
    <>
      <Box
        display="grid"
        gap={4}
        sx={{
          gridTemplateAreas: {
            xs: `
            "nav-buttons action-buttons"
            "domain-menu domain-menu"
          `,
            lg: `
            "nav-buttons nav-buttons"
            "domain-menu action-buttons"
          `,
          },
          gridTemplateColumns: '1fr auto',
        }}
      >
        <Box sx={{ gridArea: 'nav-buttons', display: 'grid', alignItems: 'center' }}>
          <Stack
            direction="row"
            alignItems="baseline"
            gap={4}
            divider={
              <Divider orientation="vertical" flexItem sx={{ borderColor: 'greys.300', my: 1.5 }} />
            }
          >
            <BackButton to={linkHelper.newDesign.sites.getLink()} />
            <Button
              variant="text"
              size="large"
              startIcon={<Share />}
              href={`https://${siteDetails?.rocket_url}`}
              target="_blank"
            >
              {t('visit_url')}
            </Button>
          </Stack>
        </Box>

        <Stack
          direction="row"
          alignItems="baseline"
          sx={{ flexWrap: 'wrap', gap: 2, alignItems: 'center', gridArea: 'domain-menu' }}
        >
          {sdk || isInIframe() ? (
            <Box>
              <Typography variant="h1" data-testid="domainLabel">
                {formatDomainName(siteDetails?.domain)}
              </Typography>
            </Box>
          ) : (
            <SiteSelectorMenu domain={siteDetails?.domain} />
          )}
          {!isSiteAccess(token) && !sdk ? (
            <Button
              onClick={() => setShowSiteLabelDialog(true)}
              variant="contained"
              color="tertiary"
              size="small"
              sx={{
                color: 'greys.500',
              }}
              disabled={isBillingAdmin}
            >
              {siteDetails?.label ?? t('add_label')}
              {siteDetails?.label ? <PencilEdit /> : null}
            </Button>
          ) : null}
        </Stack>

        <Box sx={{ gridArea: 'action-buttons' }}>
          <RoleGuard roles={['billing_admin']} type="block">
            <Stack direction="row" gap={2}>
              <ClearCacheDialog />
              <SiteLockedPopover isSiteLocked={isSiteLocked}>
                <LoadingButton
                  size="large"
                  onClick={() => {
                    openExternalLink({
                      iframeUrl: `/manage/${siteId}/wordpress?token=${window.sessionStorage.getItem(
                        'user_session'
                      )}`,
                      url: `/manage/${siteId}/wordpress`,
                      relative: true,
                    });
                  }}
                  variant="contained"
                  color="tertiary"
                  startIcon={<Wordpress />}
                  fullWidth
                  disabled={isSiteLocked}
                  loading={isLoading}
                >
                  {t('wp_admin')}
                </LoadingButton>
              </SiteLockedPopover>
            </Stack>
          </RoleGuard>
        </Box>
      </Box>

      {siteDetails && showSiteLabelDialog ? (
        <ChangeLabelDialog site={siteDetails} onClose={() => setShowSiteLabelDialog(false)} />
      ) : null}
    </>
  );
};
