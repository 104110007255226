import { CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import CheckboxChecked from 'component/new_design/icons/CheckboxChecked.svg?react';
import Checkbox from './Checkbox';

interface CheckboxFieldProps extends Omit<CheckboxProps, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly label: ReactNode;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
}

export const CheckboxField = ({ name, label, rules, ...rest }: CheckboxFieldProps) => {
  const {
    field: { ref, value, ...field },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  const helperText = error?.message || rest.helperText;

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            icon={<span />}
            checkedIcon={<CheckboxChecked />}
            inputRef={ref}
            {...rest}
            {...field}
            checked={value}
            disabled={field.disabled || isSubmitting}
          />
        }
        label={label}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
