import { useId, useState } from 'react';
import Plus from '../icons/plus.svg?react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../base/dialogs/FormDialog';
import { Button, Stack, Typography } from '@mui/material';
import { TextField } from '../base/forms/TextField';
import { useInviteUser } from 'api/users';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { components } from 'openapi-types';
import { Label } from '../base/forms/Label';
import { CheckboxField } from '../base/forms/CheckboxField';
import {
  convertToRolesArray,
  rolesDefaultValues,
  rolesFormResolver,
  UserRoleCheckboxes,
} from 'utils/user';

type InviteUserFormData = Omit<components['schemas']['CreateUserRequest'], 'roles'> &
  UserRoleCheckboxes;

export default function InviteUser() {
  const { t } = useTranslation();
  const { mutateAsync } = useInviteUser();
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const methods = useForm<InviteUserFormData>({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      ...rolesDefaultValues,
    },
    resolver: rolesFormResolver,
  });

  const rolesLabelId = useId();

  function handleClose() {
    methods.reset();
    setShowAddDialog(false);
  }

  const onSubmit = async (data: InviteUserFormData) => {
    await mutateAsync({ ...data, roles: convertToRolesArray(data) });

    enqueueSnackbar(t('user_invited_successfully'), {
      key: 'inviteUser',
      variant: 'success',
    });

    handleClose();
  };

  return (
    <>
      <FormDialog
        form={
          <Stack spacing={4} direction="column">
            <TextField
              name="firstname"
              label={t('first_name')}
              fullWidth
              rules={{ required: true }}
            />
            <TextField
              name="lastname"
              label={t('last_name')}
              fullWidth
              rules={{ required: true }}
            />
            <TextField name="email" fullWidth label={t('email')} rules={{ required: true }} />
            <Stack role="group" aria-labelledby={rolesLabelId}>
              <Label id={rolesLabelId}>{t('roles')}</Label>
              <CheckboxField name="role_account_user" label={t('account_user')} />
              <CheckboxField name="role_billing_admin" label={t('billing_admin')} />
              <CheckboxField name="role_user_admin" label={t('user_admin')} />
            </Stack>
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={
          <Stack>
            <Typography variant="body1" fontWeight={600} color="greys.900">
              {t('invite_user')}
            </Typography>
            <Typography variant="body2" color="greys.500">
              {t('invite_user_details')}
            </Typography>
          </Stack>
        }
        submitText={t('invite')}
      />
      <Button
        onClick={() => {
          setShowAddDialog(true);
        }}
        size="medium"
        variant="contained"
        color="primary"
        startIcon={<Plus />}
      >
        {t('invite_user')}
      </Button>
    </>
  );
}
