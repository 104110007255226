import { linkHelper } from 'linkHelper';
import { Route, Routes } from 'react-router-dom';
import Authenticated from '../layout/Authenticated';
import { lazy, Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import '../theme/base/variables.css';
import '../theme/base/reset.css';
import '../theme/base/fonts.css';
import SiteDetails from '../view/SiteDetails';
import Settings from '../view/Settings';
import { useSetRouteTitle } from 'component/hooks/useSetRouteTitle';
import { AutoThemeProvider } from './AutoThemeProvider';
import { SnackbarProvider } from 'notistack';
import { Snackbar } from '../base/Snackbar';

const Dashboard = lazy(() => import('component/new_design/view/Dashboard'));
const Sites = lazy(() => import('component/new_design/view/Sites'));
const ViewSiteTemplate = lazy(() => import('../partial/ViewSiteTemplate'));

export default function App() {
  useSetRouteTitle();

  return (
    <AutoThemeProvider>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        Components={{ success: Snackbar, error: Snackbar }}
      >
        <Routes>
          <Route
            path={linkHelper.newDesign.dashboard.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Dashboard />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.newDesign.settings.siteTemplates.viewTemplate.path}
            element={
              <Authenticated>
                <Suspense>
                  <ViewSiteTemplate />
                </Suspense>
              </Authenticated>
            }
          />
          <Route path={linkHelper.newDesign.sites.path}>
            <Route
              index
              element={
                <Authenticated>
                  <Suspense fallback={null}>
                    <Sites />
                  </Suspense>
                </Authenticated>
              }
            />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.path}
              element={
                <Authenticated>
                  <Suspense fallback={null}>
                    <SiteDetails />
                  </Suspense>
                </Authenticated>
              }
            />
          </Route>
          <Route
            path={linkHelper.newDesign.settings.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Settings />
                </Suspense>
              </Authenticated>
            }
          />
        </Routes>
      </SnackbarProvider>
    </AutoThemeProvider>
  );
}
