import { EMPTY_VALUE_PLACEHOLDER } from './formatting';

interface FormatDate {
  date: string;
  countryCode?: string;
  dateStyle?: 'short' | 'medium' | 'long';
  timeStyle?: 'short' | 'medium' | 'long';
}

export const formatDate = (data: FormatDate) => {
  const newDate = new Date(data.date);

  if (isNaN(newDate.getTime())) return EMPTY_VALUE_PLACEHOLDER;

  const options = {
    dateStyle: data.dateStyle ?? 'medium',
    timeStyle: data.timeStyle ?? 'short',
  };
  const formatted = new Intl.DateTimeFormat(data.countryCode ?? 'en-US', options as object).format(
    newDate
  );
  return formatted;
};

export const formatLocaleDate = ({
  locale,
  value,
  includeTime = true,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
}: {
  locale: string;
  value: number | string | Date | null | undefined;
  includeTime?: boolean;
  options?: Intl.DateTimeFormatOptions;
}) => {
  if (!value) return EMPTY_VALUE_PLACEHOLDER;

  const date = new Date(value);

  if (isNaN(date.getTime())) return EMPTY_VALUE_PLACEHOLDER;

  const datePart = date.toLocaleString(locale, options);

  if (includeTime) {
    const timePart = date.toLocaleString(locale, { hour: '2-digit', minute: '2-digit' });

    return `${datePart} \u00b7 ${timePart}`;
  }

  return datePart;
};
