import { Button } from '@mui/material';
import ChevronLeft from '../icons/ChevronLeft.svg?react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  readonly to: string;
}

export function BackButton({ to }: BackButtonProps) {
  const { t } = useTranslation();

  return (
    <Button component={Link} to={to} variant="text" size="large" startIcon={<ChevronLeft />}>
      {t('back')}
    </Button>
  );
}
