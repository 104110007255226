import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { TableColumnType, TableRowType, InnerTableProps } from './types';
import { TableRowActions, actionsColumn } from './TableRowActions';
import { TableSkeletonRows } from './TableSkeletonRows';
import { TableHead } from './TableHead';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { TableRowWrapper } from './TableRowWrapper';
import { ExpandedRowWrapper } from './ExpandedRowWrapper';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';

export const Table = <T extends TableRowType>({
  label,
  isLoading,
  data,
  state,
  rowActions,
  columns,
  renderExpandedRow,
  rowIdKey = 'id',
  emptyState,
  minHeight,
}: InnerTableProps<T>) => {
  const { t } = useTranslation();

  const [expandedRowId, setExpandedRowId] = useState<string | number | null>(null);

  const { params, setSort } = state;

  useEffect(() => {
    setExpandedRowId(null);
  }, [params]);

  const nonEmptyColumns = useMemo(() => columns.filter(Boolean) as TableColumnType<T>[], [columns]);

  const showActionsColumn = !!rowActions?.length;

  const loadingState = usePaginatedListLoadingState(!!data.length, isLoading, params);

  const getCellContent = (row: T, column: NonNullable<TableColumnType<T>>) => {
    if (column.renderValue) {
      return column.renderValue(row);
    } else if (column.key) {
      return String(row[column.key]);
    } else {
      return '-';
    }
  };

  const gridTemplateColumns = useMemo(
    () =>
      ([...nonEmptyColumns, showActionsColumn ? actionsColumn : null] as TableColumnType<T>[])
        .filter(Boolean)
        .map(({ width, minWidth }) => `minmax(${minWidth || 'max-content'}, ${width ?? 1}fr)`)
        .join(' '),
    [nonEmptyColumns, showActionsColumn]
  );

  if (loadingState === 'noData') {
    return emptyState;
  }

  return (
    <TableContainer
      sx={{
        minHeight,
      }}
    >
      <MuiTable aria-label={label} sx={{ gridTemplateColumns }} component="div" role="table">
        <TableHead
          columns={nonEmptyColumns}
          hasActionsColumn={showActionsColumn}
          sortBy={params.sortBy}
          sortDirection={params.sortDirection}
          onSort={setSort}
        />
        <TableBody component="div" role="rowgroup">
          {isLoading ? (
            <TableSkeletonRows
              columns={nonEmptyColumns}
              perPage={params.perPage}
              hasActionsColumn={showActionsColumn}
            />
          ) : (
            data.map(row => (
              <Fragment key={row[rowIdKey]}>
                <TableRowWrapper
                  onToggleExpanded={() =>
                    setExpandedRowId(row[rowIdKey] === expandedRowId ? null : row[rowIdKey])
                  }
                  isExpanded={row[rowIdKey] === expandedRowId}
                  enableExpandableRows={!!renderExpandedRow}
                >
                  <TableRow component="div" role="row">
                    {nonEmptyColumns.map(column => (
                      <TableCell
                        key={column.label}
                        align={column.align || 'left'}
                        component="div"
                        role="cell"
                      >
                        {getCellContent(row, column)}
                      </TableCell>
                    ))}
                    {showActionsColumn ? (
                      <TableCell align="right" component="div" role="cell">
                        <TableRowActions row={row} actions={rowActions} />
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableRowWrapper>
                {!!renderExpandedRow && row[rowIdKey] === expandedRowId ? (
                  <ExpandedRowWrapper>{renderExpandedRow(row)}</ExpandedRowWrapper>
                ) : null}
              </Fragment>
            ))
          )}
        </TableBody>
      </MuiTable>

      {loadingState == 'noResults' ? (
        <Typography my={10} textAlign="center">
          {t('no_results_found')}
        </Typography>
      ) : null}
    </TableContainer>
  );
};
