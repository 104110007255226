import { Button, Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableColumnType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from '../base/EmptyState';
import { formatDataSize } from 'utils/number';
import { User, useUserRevokeSites } from 'api/users';
import { useEffect, useState } from 'react';
import { SiteListItem, useSitesNew } from 'api/site';
import { SiteLocationCell } from './tables/sites/SiteLocationCell';
import { DomainCell } from './tables/sites/DomainCell';
import { LoadingButton } from '@mui/lab';
import { TableDialog } from '../base/dialogs/TableDialog';
import { useUserSiteInvites } from 'api/auth';
import { useSnackbar } from 'component/hooks/useSnackbar';

// icons
import Monitor from '../icons/Monitor.svg?react';
import Plus from '../icons/Plus.svg?react';
import Checkbox from '../base/forms/Checkbox';

export function UserSelectSites({
  currentSites,
  user,
}: {
  currentSites: number[];
  user: User | null;
}) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const tableState = usePaginatedListState({
    urlStatePrefix: 'userAssignSites',
    perPageOptions: [5],
  });
  const [selectedSites, setSelectedSites] = useState<number[]>(currentSites ?? []);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutateAsync: userSiteInvites } = useUserSiteInvites();
  const { mutateAsync: userRevokeSites } = useUserRevokeSites();
  const { data, isLoading } = useSitesNew(tableState, { enabled: showModal });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    currentSites.forEach(siteId => {
      if (!selectedSites.includes(siteId)) {
        setSelectedSites([...selectedSites, siteId]);
      }
    });
  }, [currentSites]);
  const columns: (TableColumnType<SiteListItem> | null)[] = [
    {
      label: t('domain'),
      key: 'domain',
      renderValue: site => (
        <Stack direction="row" gap={2}>
          <Checkbox
            checked={site.id ? selectedSites.includes(site.id) : false}
            onChange={(_event, value) => {
              if (!site.id) {
                return;
              }
              if (!value) {
                setSelectedSites(selectedSites.filter(id => id !== site.id));
              } else {
                setSelectedSites([...selectedSites, site.id]);
              }
            }}
            icon={<span />}
          />
          <DomainCell site={site} showStagingLink={false} />
        </Stack>
      ),
      sortable: true,
      width: 3,
    },
    {
      label: t('disk_usage'),
      renderValue: site => formatDataSize(site.disk_usage),
      key: 'disk_usage',
      sortable: true,
    },
    {
      label: t('location'),
      renderValue: site => <SiteLocationCell site={site} />,
      width: 2,
    },
  ];

  return (
    <>
      <TableDialog
        title={
          <Stack gap={1}>
            <Typography variant="body1" fontWeight={600} color="greys.900">
              {t('assign_sites_to_user')}
            </Typography>
            <Typography variant="body2" color="greys.900">
              {t('assign_sites_to_user_description')}
            </Typography>
          </Stack>
        }
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="sm"
        tableProps={{
          minHeight: `${57 * tableState.params.perPage}px`,
          title: (
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontWeight={600} variant="body1" color="greys.900">
                {t('sites')}
              </Typography>
              <Chip
                variant="badge"
                label={selectedSites.length}
                sx={{
                  color: 'greys.500',
                }}
              />
            </Stack>
          ),
          columns,
          data: data?.data?.result || [],
          totalRowCount: data?.data?.metadata?.total || 0,
          isLoading,
          state: tableState,
          searchPlaceholder: t('search_sites_label'),
          enableSearch: true,
          enablePagination: true,
          emptyState: <EmptyState icon={<Monitor />} title={t('no_sites_yet')} />,
        }}
        actions={
          <>
            <LoadingButton
              size="large"
              color="primary"
              variant="contained"
              onClick={async () => {
                const sitesToInvite: string[] = [];
                const sitesToRevoke: string[] = [];
                setIsSubmitting(true);

                selectedSites.map(siteId => {
                  if (!currentSites.includes(siteId)) {
                    sitesToInvite.push(String(siteId));
                  }
                });

                currentSites.map(siteId => {
                  if (!selectedSites.includes(siteId)) {
                    sitesToRevoke.push(String(siteId));
                  }
                });

                const promises: Promise<{ data: { success: boolean } }>[] = [];

                if (sitesToInvite.length) {
                  promises.push(
                    userSiteInvites({
                      firstName: user?.firstname ?? '',
                      lastName: user?.lastname ?? '',
                      email: user?.email ?? '',
                      siteIds: sitesToInvite,
                    })
                  );
                }

                if (sitesToRevoke.length) {
                  userRevokeSites({
                    siteIds: sitesToRevoke,
                  });
                }

                Promise.all(promises).then(values => {
                  setIsSubmitting(false);
                  setShowModal(false);

                  if (sitesToInvite.length && sitesToRevoke.length) {
                    enqueueSnackbar(
                      t('assign_sites_to_user_revoke_and_assign_success', {
                        assignCount: sitesToInvite.length,
                        revokeCount: sitesToRevoke.length,
                      }),
                      {
                        key: 'assignSitesToUser',
                        variant: 'success',
                      }
                    );
                  } else if (sitesToInvite.length) {
                    enqueueSnackbar(
                      t('assign_sites_to_user_success', {
                        count: sitesToInvite.length,
                      }),
                      {
                        key: 'assignSitesToUser',
                        variant: 'success',
                      }
                    );
                  } else if (sitesToRevoke.length) {
                    enqueueSnackbar(
                      t('assign_sites_to_user_revoke_success', {
                        count: sitesToRevoke.length,
                      }),
                      {
                        key: 'assignSitesToUser',
                        variant: 'success',
                      }
                    );
                  }
                });
              }}
              loading={isSubmitting}
            >
              {t('save_changes')}
            </LoadingButton>
          </>
        }
      />
      <Button
        onClick={() => setShowModal(true)}
        size="medium"
        variant="contained"
        color="secondary"
        startIcon={<Plus />}
      >
        {t('assign_new_site')}
      </Button>
    </>
  );
}
